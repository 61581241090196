*, *::after, *::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    user-select: none;
}
html {
    overflow: hidden;
}
#mfe:focus {
    outline: none;
}
#mfe {
    font-size: 20px;
}
@media only screen and (min-width: 600px) {
    *::-webkit-scrollbar {
            width: 16px;
        }
    
        *::-webkit-scrollbar-track {
            border-radius: 8px;
        }
    
        *::-webkit-scrollbar-thumb {
            height: 56px;
            border-radius: 8px;
            border: 4px solid transparent;
            background-clip: content-box;
            background-color: #888;
        }
    
        *::-webkit-scrollbar-thumb:hover {
            background-color: #555;
        }
}